import React, { Component } from 'react';
import {Container} from 'react-bootstrap';

class DonateBlood extends Component {
  render() {
    return (
      <Container className="nopadding">
        <p align="center">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSed0DxaLWG_hcZJVjVUZrGqoO4EsZWOD8sNFpTuQkS50pFAGA/viewform?embedded=true" width="640" height="1186" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </p>
      </Container>
    );
  }
}

export default DonateBlood;
